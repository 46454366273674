(function($) {
	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.player__volume-btn').click(function() {
		if ($(this).hasClass('player__volume-btn_off')) {
			$(this).removeClass('player__volume-btn_off');
			$(this).find('.fa-volume-off').removeClass('fa-volume-off').addClass('fa-volume-up');
			$('.video-play').YTPUnmute();
		} else {
			$(this).addClass('player__volume-btn_off');
			$(this).find('.fa-volume-up').removeClass('fa-volume-up').addClass('fa-volume-off');
			$('.video-play').YTPMute();
		}
	});

	$(".video-play").YTPlayer();

	// $('#hp-banners').slick({
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 1000,
	// 	fade: true,
	// 	rtl: dirRtlFlag,
	// 	arrows: false,
	// 	autoplay: false,
	// 	autoplaySpeed: 5000,
	// 	cssEase: 'linear',
	// 	adaptiveHeight: true,
	// 	pauseOnHover: false
	// });

})($);