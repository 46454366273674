$(function() {

	$(window).scroll(function() {
		var x = $(this).scrollTop(); /* scroll pos */
		var y = $("html").height();
		var z = x / y;

		curTop = (((z * 800) + 0));
		$("#banners-scroll").css("top", (curTop + 0) + "px");
	});

	var hasWebRTC = navigator.getUserMedia ||
			navigator.webkitGetUserMedia ||
			navigator.mozGetUserMedia ||
			navigator.msGetUserMedia;

	if (!hasWebRTC) {
		// $('.wrap-banner-scroll').css('position', 'static');
	}

});
