$(document).ready(function() {

	$('body').on('submit', '.form-body', function(e) {

		var ele = '.form-body';
		if (e.isDefaultPrevented()) {
			// handle the invalid form...
		} else {
			$.ajax({
				type: "POST",
				url: $(ele).attr('action'),
				data: $(ele).serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.status == "success") {

						$('.msg-success').removeClass('hidden');
						$(ele + ' input:not(button[type="submit"]), textarea').val("");

						setTimeout(function() {
							$('.msg-success').addClass('hidden');
						}, 6000);
					} else {

					}
				}
			});
		}
		return false;
	});

});